import PropTypes from "prop-types"
import React from "react"
import {AppBar, Toolbar, Button} from "@material-ui/core"
import Logo from '../components/Logo'
import Kickstarter from '../components/Kickstarter'
import './header.css'
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header>
    <div id="header">
    <AppBar style={{ background: 'white', boxShadow: 'none'}}>
        <Toolbar>
        <Logo></Logo>
        <Link to="/#wir">
         <Button onClick={() => window && window._paq ? window._paq.push(['trackEvent', 'Interaktionen', 'Über Uns', "Button clicked" ]) : console.log("Failed to push event")
  } className="myButton" variant="contained"  color="primary" disableElevation>Über Uns</Button>
        </Link>
        <Link to="/#tasche">
         <Button onClick={() => window && window._paq ? window._paq.push(['trackEvent', 'Interaktionen', 'Tasche', "Button clicked" ]) : console.log("Failed to push event")
  } className="myButton" variant="contained" color="primary" disableElevation>Tasche</Button>
        </Link>
        <Link to="/#folgeUns">
         <Button onClick={() => window && window._paq ? window._paq.push(['trackEvent', 'Interaktionen', 'Folge Uns', "Button clicked" ]) : console.log("Failed to push event")
  } className="myButton" variant="contained" color="primary" disableElevation>Folge Uns</Button>
        </Link>

       


        </Toolbar>
      </AppBar>
      </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
