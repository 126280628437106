/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Link from '@material-ui/core/Link';
import InstagramSmall from '../components/InstagramSmall'
import FacebookSmall from '../components/FacebookSmall'
import Grid from '@material-ui/core/Grid';

import Header from "./header"
import "./layout.css"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <footer style={{paddingBottom:"65px", backgroundColor:'#fb8c00'}}>
            
          <div style={{display: 'flex', 'justifyContent': 'space-around',backgroundColor:'#fb8c00'}}>
            
            <Grid
  container
  direction="column"
  justify="center"
  alignItems="center"
><div>
            <Link style={{paddingRight:"20px"}}href="/datenschutz">Datenschutz</Link>
      
            <Link href="/impressum">Impressum</Link>

            <InstagramSmall></InstagramSmall>
            <FacebookSmall></FacebookSmall>
            </div>
            <div>© <small>{new Date().getFullYear()}, All Rights Reserved by Geschwister Albers</small></div>
         
            </Grid>

            
            
           </div>
                     </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
