import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "./Features.css"
import { Grid, Container, Typography } from "@material-ui/core"

import IconButton from '@material-ui/core/IconButton';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Kickstarter = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Kickstarter.png" }) {
          childImageSharp {
            fluid(quality: 90,maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="kickstarter">
          <Container maxWidth="md" height="400px">
          <Grid container  direction="row" alignItems="center" justify= "center">
            <Grid container item xs={12} sm={6} direction="row" alignItems="center" justify= "center">
          
            <Typography variant="subtitle1" align="left" paragraph>
              Sichere dir jetzt dein Early Bird Angebot auf 
             </Typography>

      
            <Grid item xs={12} sm={6} > 
            <Link href="https://www.kickstarter.com/projects/geschwisteralbers/eine-sport-und-freizeittasche-so-individuell-wie-du" target="_blank" rel="noopener"> 
        
           
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
 
        </Link>
        </Grid>
        </Grid>
       
       
        </Grid>
          </Container>
    </div>
    }
  />
)

  
export default Kickstarter
